import React from 'react';
import "../Styles/Department.css";
import program_1 from "../assets/heart.avif";
import program_2 from "../assets/anasthesiology.jpg";
import program_3 from "../assets/child.jpg";
import program_4 from "../assets/nephro.jpg";
import program_5 from "../assets/gy.jpg";
import program_6 from "../assets/general.avif";

function Departments() {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="program">
            <img src={program_1} alt="Cardiology" className="img-fluid" />
            <div className="caption">
              <h4>CARDIOLOGY</h4>
            </div>
          </div>
        </div>
        
        <div className="col-md-4 mb-4">
          <div className="program">
            <img src={program_2} alt="Anasthesiology" className="img-fluid" />
            <div className="caption">
              <h4>ANASTHESIOLOGY</h4>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4">
          <div className="program">
            <img src={program_3} alt="Paediatrics" className="img-fluid" />
            <div className="caption">
              <p>PAEDIATRICS</p>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4">
          <div className="program">
            <img src={program_4} alt="Nephrology" className="img-fluid" />
            <div className="caption">
              <p>NEPHROLOGY</p>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4">
          <div className="program">
            <img src={program_5} alt="Gynaecology" className="img-fluid" />
            <div className="caption">
              <p>GYNAECOLOGY</p>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4">
          <div className="program">
            <img src={program_6} alt="General Surgery" className="img-fluid" />
            <div className="caption">
              <p>GENERAL SURGERY</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Departments;
