import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import "../Styles/Financialinfo.css"

// Register necessary chart components
Chart.register(ArcElement, Tooltip, Legend, Title);

function PieChart({ data, title }) {
    // Set default data to avoid undefined errors
    const chartData = {
        labels: data?.labels || ['Expenses', 'Profit ','Loss' ,'Cas flow','Outstanding Payments'], // Default labels
        datasets: [
            {
                data: data?.values || [20, 10,20, 30,20,],  // Default values
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56','#526E48',"#AF1740"], // Customize colors
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56','#526E48',"#AF1740"],
            },
        ],
    };

    // Chart options for customization
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,  // Allows the chart to resize with the container
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: !!title,
                text: title,
            },
        },
    };

    return (
        <div className="pie-chart-container">
            <Pie data={chartData} options={chartOptions} />
        </div>
    );
}

export default PieChart;
