// HospitalTable.js
import React from "react";
import { Table } from "react-bootstrap";
import "../Styles/Table.css";

const HospitalTable = ({ data }) => {
    return (
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>Department</th>
              <th>Doctor Assigned</th>
              <th>Admission Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((patient, index) => (
              <tr key={index}>
                <td>{patient.name}</td>
                <td>{patient.department}</td>
                <td>{patient.doctor}</td>
                <td>{patient.admissionDate}</td>
                <td>{patient.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
  );
};

export default HospitalTable;
