import React from 'react'
import '../Styles/About.css';
import about_img from '../assets/doctor.webp';


function About() {
  return (
    <div className='about'>
      <div className="about-left">
        <img src={about_img} alt="" className='about-img'/>
        
      </div>
      <div className="about-right">
        <h3>ABOUT US</h3>
        <h2>Welcome to Medico</h2>
        <p>Welcome to Medico, where compassionate care and advanced medical services meet. Since our founding in [Year], we have been dedicated to providing high-quality healthcare that combines clinical excellence with a patient-centered approach.</p>
        <h2>Our Mission</h2>
        <p>At Medico, our mission is to improve the health and well-being of our community through compassionate care, exceptional medical treatment, and a commitment to excellence. We believe that every patient deserves personalized attention and access to the latest advancements in medical science.</p>
      </div>
    </div>
  );
}

export default About;
