import React from 'react';
import '../Styles/Testimonials.css';

function Testimonials() {
    const testimonials = [
        { name: "Dr. John Doe", feedback: "This system has revolutionized our workflow and patient care!" },
        { name: "Nurse Jane Smith", feedback: "Scheduling appointments is now so much easier!" },
        { name: "Administrator Alex Johnson", feedback: "The analytics tools provide invaluable insights " },
        // Add more testimonials as needed
    ];

    return (
        <div className="testimonials-section container">
            <h2 className="text-center">What Our Clients Say</h2>
            <div className="row mt-4">
                {testimonials.map((testimonial, index) => (
                    <div className="col-md-6 col-lg-4 mb-4" key={index}>
                        <div className="testimonial-card p-3">
                            <p className="text-muted">"{testimonial.feedback}"</p>
                            <h4 className="text-primary mt-3">- {testimonial.name}</h4>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Testimonials;
