import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Topbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faCaretDown } from '@fortawesome/free-solid-svg-icons';

function TopBar({ toggleSidebar }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.profile-container')) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Handle logout action
    const handleLogout = () => {
        // Perform any logout logic here, such as clearing user data or tokens

        // Navigate to the login page
        navigate('/login');
    };

    return (
        <div className="topbar">
            {/* Toggler Icon for Mobile */}
            <FontAwesomeIcon icon={faBars} className="sidebar-toggler" onClick={toggleSidebar} />
            <div className="logos">Medico</div>

            {/* Right-side container for search, profile, and dropdown */}
            <div className="topbar-right">
                <div className="search">
                    <input type="text" placeholder="Search..." />
                </div>

                <div className="user-profile">
                    <FontAwesomeIcon icon={faBell} className="notification-icon" />

                    {/* Profile and Dropdown */}
                    <div className="profile-container">
                        <div className="profile" onClick={toggleDropdown}>
                            <img src="profile-pic.jpg" alt="User" className="profile-pic" />
                            <span className="username">Admin</span>
                            <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon" />
                        </div>

                        {/* Dropdown Menu */}
                        {dropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item">Profile</div>
                                <div className="dropdown-item" onClick={handleLogout}>
                                    Log out
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
