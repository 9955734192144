import React, { useState } from "react";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import Chart from "../components/Chart";
import Pichart from "../components/Financialinfo"; // Pie chart component
import { Link } from 'react-router-dom';
import '../Styles/Dashboard.css';
import HospitalTable from "../components/Table";

function Dashboard() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
  
    const toggleSidebar = () => {
        setSidebarOpen(prevState => !prevState);
    };
  
    const patientData = [
        {
            name: "John Doe",
            department: "Cardiology",
            doctor: "Dr. Smith",
            admissionDate: "2023-11-01",
            status: "Under Treatment",
        },
        {
            name: "Jane Smith",
            department: "Neurology",
            doctor: "Dr. Adams",
            admissionDate: "2023-11-02",
            status: "Stable",
        },
        // Add more patient data as needed
    ];
    return (
        <div className="dashboard-layout">
            <Sidebar className={sidebarOpen ? 'open' : ''} />
            <div className="main-content">
                <Topbar toggleSidebar={toggleSidebar} />
                <div className="content-area">
                    <h2>Dashboard</h2>
                    <hr />
                    <div className="dashboard-container">
                        <div className="dashboard-grid">
                            <div className="dashboard-card"><h2>Active Doctors</h2><p>10</p></div>
                            <div className="dashboard-card"><h2>Active Nurse</h2><p>30</p></div>
                            <div className="dashboard-card"><h2>Active Patients</h2><p>100</p></div>
                            <div className="dashboard-card"><h2>Upcoming Appointments</h2><p>50</p></div>
                            <div className="dashboard-card"><h2>Pharmacist</h2><p>20</p></div>
                            <div className="dashboard-card"><h2>Accountant</h2><p>10</p></div>
                            <div className="dashboard-card"><h2>Laboratorist</h2><p>10</p></div>
                            <div className="dashboard-card"><h2>Receptionist</h2><p>10</p></div>
                        </div>

                        {/* Hospital Resources Section */}
                        <div className="hospital-resources" style={{ marginTop: 150 }}>
                            <h2>Hospital Resources</h2>
                            <Chart />
                        </div>

                        {/* Financial Overview Section (Pie Chart) */}
                        <div className="financial-overview" style={{ marginTop: 50, color: "#3c6382" }}>
                            <h2>Financial Overview</h2>
                            <Pichart />
                        </div>

                        {/* Hospital Table Section */}
                        <div className="hospital-table" style={{ marginTop: 50, color: "#3c6382", textAlign: "center" }}>
                            <h2>Hospital Patient List</h2>
                            <HospitalTable data={patientData} /> {/* Pass patient data to the table */}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar} />
        </div>
    );
}

export default Dashboard;
