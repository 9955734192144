import React from 'react'
import '../Styles/Footer.css'

function Footer () {
  
    return (
      <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Medico</h3>
          <p>Medico Hospital</p>
          <p>Thrissur</p>
          <p>Email: info@medico.com</p>
          <p>Phone: (123) 456-7890</p>
        </div>
        <div className="footer-section">
          <h3>Center of Excellance</h3>
          <ul>
            <li>Cardiac center</li>
            <li>Onco Center</li>
            <li>All departments</li>
           
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact Numbers</h3>
          <p>Ambulance: 0487-2678543</p>
          <p>Appointment Booking: 0487-2678547 </p>
        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <ul>
            <li>Facebook</li>
            <li>Twitter</li>
            <li>Instagram</li>
          </ul>
          
        </div>
      </div>
     
      <div className=" footer-bottom">
        <p>&copy; 2024 Medico. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer
