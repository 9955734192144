import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../Styles/Hero.css';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


// Example images - replace these with paths to your actual images
import image1 from '../assets/home.png';
import image2 from '../assets/home4.jpeg';
import image3 from '../assets/home3.jpg';

const Hero = () => {
  return (
    <div className='hero'>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={image1} alt="First slide" />
          <Carousel.Caption>
            <h1>Complete Healthcare Solution</h1>
            <p>Healthcare is not a privilege, it’s a responsibility. Together, we can make it better.</p>
            <Link to="/login" className="btn">
              Explore more
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item>
          <img className="d-block w-100" src={image2} alt="Second slide" />
          <Carousel.Caption>
            <h1>Advanced Medical Facilities</h1>
            <p>Providing top-notch medical care and advanced treatment options.</p>
            <Link to="/services" className="btn">
              Our Services
            </Link>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={image3} alt="Third slide" />
          <Carousel.Caption>
            <h1>24/7 Emergency Support</h1>
            <p>Always ready to assist with our dedicated emergency services.</p>
            <Link to="/contact" className="btn">
              Contact Us
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Hero;
