import React from 'react';
import '../Styles/Healthcare.css';
import Healthcare from '../assets/img2.webp'
import { FaProcedures, FaUserFriends } from 'react-icons/fa';

const HealthcareSection = () => {

    
  return (
    <section className="healthcare-section">
      <h1 className="title">Innovative Quality in Healthcare</h1>
      <p className="subtitle">Bringing Global Healthcare Standards to Your Door Steps</p>
      
      <div className="features-container">
        
        <div className="feature-item">
          <div className="icon-container">
          <FaProcedures size={30} />
          </div>
          <h3 className="feature-title">Standard Treatment</h3>
          <p className="feature-description">
            Elevate your health with our standard of treatment
          </p>
        </div>
        
        <div className="image-container">
          <img src={Healthcare} alt="Healthcare" className="healthcare-image" />
        </div>
        
        <div className="feature-item">
          <div className="icon-container">
          <FaUserFriends size={30} />
          </div>
          <h3 className="feature-title">Patient Experience</h3>
          <p className="feature-description">
            Your experience matters, and we're committed to making it exceptional
          </p>
        </div>
      </div>
    </section>
  );
};

export default HealthcareSection;
