import React, { useEffect, useState } from 'react';

const Counter = ({ target, duration, suffix }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const increment = target / (duration / 10);

        const counter = setInterval(() => {
            start += increment;
            if (start >= target) {
                setCount(target);
                clearInterval(counter);
            } else {
                setCount(Math.ceil(start));
            }
        }, 10);

        return () => clearInterval(counter);
    }, [target, duration]);

    return (
        <div className="counter">
            {count}{suffix}
        </div>
    );
};

export default Counter;
