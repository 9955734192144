import React, { useState } from 'react'
import Navbar from "./Navbar";
import Hero from "./Hero";
import Title from './Tittle';
import Department from "./Departments";
import About from "./About";
import Footer from "./Footer"
import Testimonials from './Testimonials';
import Healthcare from "./Healthcare";
import '../Styles/Home.css'
import { FaClinicMedical, FaUserNurse, FaCapsules } from 'react-icons/fa';
import Counter from "./Counter";


function Home(){
  return(
    <div className='container-homes'>
      <Navbar />
      <div className="carousel-wrapper">
        <div className='homes'>
          <Hero />
          <div className='home-container'>
            <About />
            <Title title='Our Departments' />
            <Department />
            <Title title='Our Services' />

            {/* Services Section */}
            <section className="services">
              <div className="row service-cards">
                <div className="col-md-4 mb-4">
                  <div className="service-card text-center p-4 shadow-sm rounded">
                    <div className="icon-wrapper mb-3">
                      <FaClinicMedical size={40} color="#0073e6" />
                    </div>
                    <h3>Emergency Care</h3>
                    <p>24/7 emergency services</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="service-card text-center p-4 shadow-sm rounded">
                    <div className="icon-wrapper mb-3">
                      <FaUserNurse size={40} color="#0073e6" />
                    </div>
                    <h3>Outpatient Services</h3>
                    <p>Comprehensive outpatient care</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="service-card text-center p-4 shadow-sm rounded">
                    <div className="icon-wrapper mb-3">
                      <FaCapsules size={40} color="#0073e6" />
                    </div>
                    <h3>Pharmacy</h3>
                    <p>Quality medicines and advice</p>
                  </div>
                </div>
              </div>
            </section>

            {/* Stats Section */}
            <section className="stats-section">
              <div className="stat-item">
                <Counter target={150} duration={2000} suffix="+" />
                <p>Qualified Doctors</p>
              </div>
              <div className="stat-item">
                <Counter target={300} duration={2000} suffix="+" />
                <p>Hospital Beds</p>
              </div>
              <div className="stat-item">
                <Counter target={60} duration={2000} suffix="+" />
                <p>ICU Beds</p>
              </div>
              <div className="stat-item">
                <Counter target={24} duration={2000} suffix="/7" />
                <p>Emergency & Trauma Care</p>
              </div>
            </section>

            <Testimonials />
            <Healthcare/>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
    
export default Home;