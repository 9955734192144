import React, { useEffect, useState } from 'react';
import '../Styles/Navbar.css';
import menu_icon from '../assets/menu-icon.png';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [sticky, setSticky] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [showTopBar, setShowTopBar] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            setSticky(currentScrollY > 50);

            if (currentScrollY > lastScrollY) {
                setShowTopBar(false);  // Hide top bar when scrolling down
            } else {
                setShowTopBar(true);   // Show top bar when scrolling up
            }
            lastScrollY = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const toggleMenu = () => setMobileMenu(!mobileMenu);

    
    const handleLoginClick = () => {
        navigate('/login');  // Redirect to the login page
    };

    const handleRegisterClick = () => {
        navigate('/register');  // Redirect to the login page
    };

    return (
        <nav className={`navbar-container ${sticky ? 'dark-nav' : ''}`}>
            {/* Conditionally render Top Bar */}
            {showTopBar && (
                <div className="top-bar">
                <div className="left-section">
                    <div className="brand-name">MEDICO</div>
                    {/* <div className="emergency-number">
                        Emergency: <span>+1 (123) 456-7890</span>
                    </div> */}
                </div>
                <div className="search-boxs">
                    <input type="text" placeholder="Search..." />
                    <button type="button" className="search-buttons">Search</button>
                </div>
            </div>
            )}

            {/* Navbar Links */}
            <ul className={`${mobileMenu ? 'show-mobile-menu' : 'hide-mobile-menu'}`}>
    <li><Link className="nav-link" to='hero' smooth={true} offset={0} duration={500}>Home</Link></li>
    <li><Link className="nav-link" to='about' smooth={true} offset={-260} duration={500}>About us</Link></li>
    <li><Link className="nav-link" to='program' smooth={true} offset={-150} duration={500}>Department</Link></li>
    <li><Link className="nav-link" to='services' smooth={true} offset={-260} duration={500}>Services</Link></li>
    <li><Link className="nav-link" onClick={handleRegisterClick} to='/register' smooth={true} offset={-260} duration={500}>Register</Link></li>
    <li><Link className="nav-link" onClick={handleLoginClick} to='/login' smooth={true} offset={-260} duration={500}>Login</Link></li>
</ul>


            {/* Mobile Menu Icon */}
            <img src={menu_icon} alt="Menu" className='menu-icon' onClick={toggleMenu} />
        </nav>
    );
};

export default Navbar;
