import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../Styles/Login.css';

function Register() {
  const navigate = useNavigate();

  const handleRegister = (event) => {
    event.preventDefault();
    // For now, we'll just navigate to the dashboard directly
    navigate('/dashboard');
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-form-section">
          <div className="logo">Medico</div>
          <h2>Register</h2>
          <form onSubmit={handleRegister}>
            <input type="text" placeholder="Full Name" required />
            <input type="email" placeholder="Email Address" required />
             {/* Radio Button */}
             <div className="form-optionss">
              <label>Gender:</label>
              <label>
                <input type="radio" name="gender" value="male" required /> Male
              </label>
              <label>
                <input type="radio" name="gender" value="female" required /> Female
              </label>
             
            </div>
            <input type="text" placeholder="User Name" required />
            <input type="password" placeholder="Password" required />
            <input type="password" placeholder="Confirm Password" required />

            {/* Address Field */}
            <div className="form-options" >
              <label>Address:</label>
              <textarea placeholder="Enter your address" rows="3" required></textarea>
            </div>

            {/* File Attachment */}
            <div className="form-options">
              <label>Attach Profile Picture:</label>
              <input type="file" name="profilePicture" accept="image/*" />
            </div>

           

            <div className="form-options">
              <label>
                <input type="checkbox" required /> Agree to Terms and Conditions
              </label>
            </div>
            
            <button type="submit">Register</button>
            <Link to="/login">Already have an account? Log In</Link>
          </form>
        </div>
        
        <div className="login-image-section">
          <div className="language-switch">English (EN)</div>
          <div className="image-placeholder">
            <p>Start your journey with Medico</p>
            <span>Sign up to connect with top doctors and experts</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
