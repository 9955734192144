import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../Styles/Login.css';

function Login() {
  const navigate = useNavigate(); // Get the navigate function

  const handleLogin = (event) => {
    event.preventDefault();
    // For now, we'll just navigate to the dashboard directly
    navigate('/dashboard');
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-form-section">
          <div className="logo">Medico</div>
          <h2>Sign In</h2>
          <form onSubmit={handleLogin}> {/* Handle the form submission */}
            <input type="text" placeholder="User Name" />
            <input type="password" placeholder="Password" />
            <div className="form-options">
              <label>
                <input type="checkbox" /> Remember me
              </label>
              <a href="#forgot-password">Forgot Password?</a>
            </div>
            <button type="submit">Log In</button>
            <Link to="/register">Don't have an account?</Link>
          </form>
        </div>
        <div className="login-image-section">
          <div className="language-switch">English (EN)</div>
          <div className="image-placeholder">
            <p>Connect with a Doctor</p>
            <span>Expert Advice from top Doctors</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
