// components/Chart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import '../Styles/Chart.css';

// Register the necessary components with Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function HospitalResourcesChart() {
    const data = {
        labels: ['Doctors', 'Nurses', 'Patients', 'Appointments', 'Pharmacists', 'Accountants', 'Laboratorists', 'Receptionists'],
        datasets: [
            {
                label: 'Hospital Resources',
                backgroundColor: 'rgba(116, 185, 255, 0.2)',
                borderColor: '#0984e3',
                pointBackgroundColor: '#0984e3',
                pointBorderColor: '#0984e3',
                fill: true,
                tension: 0.3, // Smoothness of the curve (0 = straight lines, 1 = fully curved)
                data: [10, 30, 100, 50, 20, 10, 10, 10], // Replace with actual data if needed
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Count',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Resources',
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    return (
        <div className="hospital-resourcess">
          
            <Line data={data} options={options} />
        </div>
    );
}

export default HospitalResourcesChart;
