import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Topbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHome,faUser, faCalendarAlt, faUsers, faFileInvoice, faCapsules, faCog,faCaretDown } from '@fortawesome/free-solid-svg-icons';



function Sidebar({ className }) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={`sidebar ${className}`}>
      <ul>
        <li><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /> Dashboard</Link></li>
        <li><Link to=""><FontAwesomeIcon icon={faUser} /> Patients</Link></li>
        <li><Link to=""><FontAwesomeIcon icon={faCalendarAlt} /> Appointments</Link></li>
        <li><Link to=""><FontAwesomeIcon icon={faUsers} /> Staff</Link></li>
        <li><Link to=""><FontAwesomeIcon icon={faFileInvoice} /> Billing</Link></li>
        <li><Link to=""><FontAwesomeIcon icon={faCapsules} /> Pharmacy</Link></li>
        
        {/* Dropdown Menu for Settings */}
        <li onClick={toggleDropdown} className="dropdowns">
          <div className="dropdown-toggles">
            <FontAwesomeIcon icon={faCog} />  Settings
            <FontAwesomeIcon icon={faCaretDown} className="dropdown-icons" />
          </div>
          {isDropdownOpen && (
            <ul className="dropdown-menus">
              <li><Link to="" className="dropdown-item">Profile</Link></li>
              <li><Link to="t" className="dropdown-item">Account</Link></li>
              <li><Link to="" className="dropdown-item">Security</Link></li>
            </ul>
          )}
        </li>
      </ul>
    </div>
    );
  }
  
  export default Sidebar;


